<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model.number="indicatorLP"
        @change="doChange"
        @blur="doBlur"
        type="number"
        dense
        outlined
        :label="expType === 'kei' ? 'Показатель ЛП' : 'Ожидаемая практика'"
        :hint="hint"
      >
        <template #append
          ><v-badge
            :offset-y="23"
            left
            color="blue lighten-3"
            :content="`Сценарий ${indicator.idx + 1}`"
          ></v-badge
        ></template>
      </v-text-field>
      <v-text-field
        v-model.number="indicatorLPCompare"
        @change="doChange"
        @blur="doBlur"
        type="number"
        dense
        outlined
        :label="
          expType === 'kei' ? 'Показатель ЛП сравнения' : 'Текущая практика'
        "
        :hint="hint"
      >
      </v-text-field>

      <v-text-field
        :value="percentDeviation"
        readonly
        hide-details
        dense
        class="mx-7"
        label="Процент отклонения"
      >
        <template #prepend><v-icon v-text="'mdi-percent'"></v-icon></template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'IndicatorsEditor',
  data: () => ({
    indicatorLP: 0,
    indicatorLPCompare: 0,
  }),
  props: {
    indicator: {
      type: Object,
      default: () => {},
    },
    hint: {
      type: String,
      default: 'Затраты',
    },
    expType: {
      type: String,
      default: 'kei',
    },
  },
  created() {
    this.init(this.indicator)
  },
  methods: {
    init(value) {
      this.indicatorLP = value.indicatorLP || 0
      this.indicatorLPCompare = value.indicatorLPCompare || 0
    },
    doChange(force) {
      this.$emit(
        'change',
        {
          indicatorLP: this.indicatorLP,
          indicatorLPCompare: this.indicatorLPCompare,
          percentDeviation: this.percentDeviation,
        },
        force
      )
    },
    doBlur() {
      this.$emit('blur')
    },
  },
  computed: {
    percentDeviation() {
      if (this.indicatorLP <= 0 || this.indicatorLPCompare <= 0) return 0
      return (
        (
          ((this.indicatorLP - this.indicatorLPCompare) /
            this.indicatorLPCompare) *
          100
        ).toFixed(2) || 0
      )
    },
  },
}
</script>
